



























































































import {Component, Watch} from "vue-property-decorator";
import Vue from "vue";
import TraitCard from "@/components/TraitCard.vue";
import {RouteNext} from "@/types";
import {vxm} from "@/store";
import SpeciesChooser from "@/components/SpeciesChooser.vue";
import {
  CreateTraitRequest, DeleteTraitRequest,
  TaxonomyBrief,
  TaxonomyPortrait,
  TaxonomyPresenceData,
  TaxonomyTrait, UpdateTraitRequest
} from "@/service";
import DeleteTraitDialog from "@/components/portraits/dialogDeleteTrait.vue";
import DeletePresenceDialog from "@/components/portraits/dialogDeletePresence.vue";
import CircleMarker from "@/components/portraits/CircleMarker.vue";
import {
  DefaultTaxonomyPresenceTraits,
  DefaultTaxonomyTrait, PORTRAIT_SVG_DEFAULT_HEIGHT, PORTRAIT_SVG_DEFAULT_WIDTH, PORTRAIT_SVG_MEASURE_DELAY
} from "@/types/portraits";
import {Route} from "vue-router";
import {delay} from "@/utils";

import * as d3 from "d3";

@Component({
  components: {
    SpeciesChooser,
    CircleMarker,
    TraitCard,
    DeleteTraitDialog,
    DeletePresenceDialog,
  }
})
export default class TaxonomyPortraitPage extends Vue {

  private editingIndex = -1;
  private creatingIndex = -1;

  private currentTraitIndex = -1;
  private showDeleteTraitDialog = false;
  private showDeletePresenceDialog = false;

  private portraitWidth = PORTRAIT_SVG_DEFAULT_WIDTH;
  private portraitHeight = PORTRAIT_SVG_DEFAULT_HEIGHT;

  private savedTrait: TaxonomyTrait = DefaultTaxonomyTrait;

  private currentPresence = DefaultTaxonomyPresenceTraits;

  private xScale = d3.scaleLinear();
  private yScale = d3.scaleLinear();
  private refreshIt = false;

  private get currentPortraitIndex(): number {
    return vxm.portraitsModule.currentPortraitIndex;
  }

  private set currentPortraitIndex(index: number) {
    vxm.portraitsModule.currentPortraitIndex = index;
  }

  private mounted() {
    console.log("TaxonomyPortrait.vue mounted", this.portraitHeight, this.portraitWidth)

    const self = this;

    delay(PORTRAIT_SVG_MEASURE_DELAY).then(function () {
      self.measure()
    })

    vxm.taxonomyStore.$watch("showDrawer", newVal => {
      console.log(`TaxonomyPortrait.vue noticed showDrawer has changed: ${newVal}`)
      delay(PORTRAIT_SVG_MEASURE_DELAY).then(function () {
        self.measure()
      })
    });
  }

  private measure() {
    this.portraitWidth = (this.$refs.portrait as Vue).$el.clientWidth;
    this.portraitHeight = (this.$refs.portrait as Vue).$el.clientHeight;

    console.log('measure', this.portraitWidth, this.portraitHeight)

    this.refreshIt = !this.refreshIt;

    if (this.portraitHeight > 0 && this.portraitWidth > 0) {
      const xRange = this.portraitWidth;
      const yRange = this.portraitHeight;
      this.xScale.domain([0, PORTRAIT_SVG_DEFAULT_WIDTH]).range([0, this.portraitWidth]);
      this.yScale.domain([0, PORTRAIT_SVG_DEFAULT_WIDTH / this.portraitWidth * this.portraitHeight]).range([0, this.portraitHeight]);
    }
  }

  private get currentTraits(): TaxonomyTrait[] {
    return vxm.portraitsModule.currentTraits;
  }

  private get currentTraitPoints(): [number, number][] {
    return this.currentTraits.map(t => t.points ?
        t.points.split(";").map(v => v.split(","))
        : [[300, 200]]);
  }

  public get currentTrait(): TaxonomyTrait {
    return this.currentTraits[this.currentTraitIndex] || DefaultTaxonomyTrait;
  }

  public traitCardIconColor(idx: number): string {
    return vxm.portraitsModule.currentTraitColors[idx];
  }

  private get portraits(): TaxonomyPortrait[] {
    return vxm.portraitsModule.portraits;
  }

  private get allSpecies(): TaxonomyBrief[] {
    return vxm.taxonomyStore.allSpecies;
  }

  private imageUrl(md5: string) {
    return process.env.VUE_APP_MEDIA_BASE_URL + `/tiny-${md5}`;
  }

  // change current imageIndex
  private onChangePortrait(index: number) {
    this.measure()
    console.log("TaxonomyPortrait.vue onChangePortrait:", index, this.portraitWidth, this.portraitHeight)
    if (+this.$route.params.media != vxm.portraitsModule.portraits[index].mediaId) {
      this.$router.replace({
        name: "TaxonomyPortrait",
        params: {
          list: this.$route.params.list,
          node: this.$route.params.node,
          media: vxm.portraitsModule.portraits[index].mediaId.toString(),
        }
      });
    }
  }

  private deletePresence(item: TaxonomyBrief) {

    this.currentPresence = vxm.portraitsModule.currentPortrait.presences
        .find(p => p.taxonomy == item.taxonomy) || DefaultTaxonomyPresenceTraits;

    console.log("try to delete species", item, this.currentPresence);

    this.showDeletePresenceDialog = true;
  }

  private addPresence(taxonomyId: number) {
    console.log("try to add presence", taxonomyId);

    const data: TaxonomyPresenceData = {
      media: +this.$route.params.media,
      origin: +this.$route.params.node,
      presenceId: 0,
      taxonomy: taxonomyId
    }

    vxm.portraitsModule.createPresence({
      listId: +this.$route.params.list,
      mediaId: data.media,
      taxonomyPresenceData: data
    })
  }

  private onEditTrait(idx: number) {
    this.measure()
    this.savedTrait = Object.assign({}, vxm.portraitsModule.currentTraits[idx]);
    this.editingIndex = idx;
  }

  private onInsertTrait(after: TaxonomyTrait) {
    const idx = vxm.portraitsModule.currentTraits.findIndex(
        tr => tr.traitId == after.traitId
    );

    if (idx >= 0) {
      vxm.portraitsModule.currentTraits.splice(idx + 1, 0, {
        media: after.media,
        taxonomy: after.taxonomy,
        presence: after.presence,
        origin: +this.$route.params.node,
        chinese: "",
        createdAt: new Date(),
        marker: 1,
        traitId: 0,
        updatedAt: new Date()
      })

      this.editingIndex = idx + 1;
      this.creatingIndex = idx + 1;
    }
  }

  private onCancelCreateTrait(trait: TaxonomyTrait) {
    vxm.portraitsModule.currentTraits.splice(this.creatingIndex, 1);
    this.editingIndex = -1;
    this.creatingIndex = -1;
  }

  private onCancelUpdateTrait(trait: TaxonomyTrait) {

    Vue.set(vxm.portraitsModule.currentTraits, this.editingIndex, this.savedTrait);

    this.editingIndex = -1;
    this.creatingIndex = -1;
  }

  private doCreateTrait(trait: TaxonomyTrait) {
    trait.origin = +this.$route.params.node;

    const query: CreateTraitRequest = {
      listId: +this.$route.params.list,
      taxonomyTraitData: trait
    }

    vxm.portraitsModule.createPresenceTrait(query);

    this.editingIndex = -1;
    this.creatingIndex = -1;
  }

  private onUpdatePoints(index: number, points: string) {
    console.log("onUpdatePoints", index, points)
    const trait = vxm.portraitsModule.currentTraits[index];
    trait.points = points;
    this.doUpdateTrait(trait)
  }

  private doUpdateTrait(trait: TaxonomyTrait) {

    trait.origin = +this.$route.params.node;

    let query: UpdateTraitRequest = {
      listId: +this.$route.params.list,
      traitId: trait.traitId,
      taxonomyTraitData: trait
    };

    vxm.portraitsModule.updateTrait(query);

    this.editingIndex = -1;
    this.creatingIndex = -1;
  }

  private onDeleteTrait(idx: number) {
    this.currentTraitIndex = idx;
    this.showDeleteTraitDialog = true;
  }

  private doDeleteTrait(trait: TaxonomyTrait) {
    trait.origin = +this.$route.params.node;

    const query: DeleteTraitRequest = {
      listId: +this.$route.params.list,
      traitId: trait.traitId,
      taxonomyTraitData: trait,
    }
    vxm.portraitsModule.deleteTrait(query)
    this.currentTraitIndex = -1;
  }

  private async beforeRouteEnter(to: Route, from: Route, next: RouteNext) {
    // vxm.main.currentTraits.splice(0, vxm.main.currentTraits.length);

    if (from.params.media != to.params.media) {
      vxm.portraitsModule.onPortraitChange(+to.params.media);
    }

    next();
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: RouteNext) {
    this.editingIndex = -1;
    this.creatingIndex = -1;

    if (from.params.media != to.params.media) {
      vxm.portraitsModule.onPortraitChange(+to.params.media);
    }
    next();
  }
}
