








































import {mixins} from 'vue-class-component';
import DialogMixin from "../DialogMixin";
import {Component, Prop} from "vue-property-decorator";
import {TaxonomyPresence, TaxonomyPresenceData, TaxonomyPresenceTraits, TaxonomyTrait} from "@/service";
import {vxm} from "@/store";
import {DefaultTaxonomyPresence} from "@/types/portraits";

@Component
export default class DeletePresenceDialog extends mixins(DialogMixin) {
  @Prop({required: true})
  presence!: TaxonomyPresenceTraits;

  private closeDialog() {
    this.show = false;
  }

  private get presenceName() : string {
    const taxonomy = this.presence.traits.find(p=>p.marker == 0)
    return taxonomy ? taxonomy.chinese : "--";
  }

  private async doDeletePresence() {
    await vxm.portraitsModule.deletePresence({
      taxonomyPresenceId: {
        presenceId: this.presence.presenceId
      },
      listId: +this.$route.params.list,
      mediaId: this.presence.media,
    });

    this.closeDialog();
  }

}
