








































import {mixins} from 'vue-class-component';
import DialogMixin from "../DialogMixin";
import {Component, Prop} from "vue-property-decorator";
import {TaxonomyTrait} from "@/service";
import {vxm} from "@/store";

@Component
export default class DeleteTraitDialog extends mixins(DialogMixin) {
  @Prop({required: true})
  trait!: TaxonomyTrait;

  private closeDialog() {
    this.show = false;
  }

  private async doDeleteTrait() {

    this.$emit("do-delete", this.trait);
    this.closeDialog();
  }

}
