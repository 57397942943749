


























import * as d3 from "d3";
import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class CircleMarker extends Vue {

  private targetX = 0;
  private targetY = 0;
  private sourceX = 0;
  private sourceY = 0;
  private controlX = 0;
  private controlY = 0;
  private hasControl = false;
  private nextAngle = 90;
  private controlWidth = 10;

  private circleRadius = 15;

  @Prop({required: true})
  private readonly xScale!: (f: number) => number

  @Prop({required: true})
  private readonly yScale!: (f: number) => number

  @Prop({type: Number, default: 0})
  private readonly index!: number;

  @Prop({type: String, default: "red"})
  private readonly color!: string;

  @Prop({type: Array, default: []})
  private readonly initPoints!: Array<[number, number]>;

  @Prop({type: Boolean, default: false, required: true})
  private readonly refreshIt!: boolean

  @Watch("initPoints")
  private onInitPointsChanged(val: Array<[number,number]>, oldVal: Array<[number,number]>) {
    const changed = JSON.stringify(val) != JSON.stringify(oldVal);
    if (changed) {
      console.log('initPoints changed', changed)
      this.init(val);
    }
  }

  @Watch("refreshIt")
  private onRefreshSelf(val: number, oldVal: number) {
    this.init(this.initPoints);
  }

  private get idTag(): string {
    return `dragdrop-${this.index}`;
  }

  private created() {
    this.init(this.initPoints);
  }

  private mounted() {
    let self = this;

    let drag = d3
      .drag<SVGElement, unknown>()
      .on("drag", function (evt) {
        let pt = d3.pointer(evt, d3.select(this).node());
        if (this.id == 'source') {
          self.sourceX = pt[0];
          self.sourceY = pt[1];
        } else if (this.id == 'target') {
          self.targetX = pt[0];
          self.targetY = pt[1];
        } else if (this.id == 'control') {

          if (evt.sourceEvent.shiftKey) {
            let angle = self.getAngle(self.targetX, self.targetY, pt[0], pt[1])
            console.log("angle: ", Math.floor(angle / 45), evt.sourceEvent.shiftKey)

            if ((angle > 337.5 || angle < 22.5) ||
              (angle > 157.5 && angle < 202.5)) {
              self.controlX = pt[0];
              self.controlY = self.targetY;
            } else if ((angle > 67.5 || angle < 112.5) ||
              (angle > 247.5 && angle < 292.5)) {
              self.controlX = self.targetY;
              self.controlY = pt[1];
            }
          } else {
            self.controlX = pt[0];
            self.controlY = pt[1];
          }
        }
      })
      .on("end", function () {
        console.log('drag.end', this.id)
        self.onDragEnd();
      })


    d3.select(`#${this.idTag}`).selectAll("circle").call(drag);
    d3.select(`#${this.idTag}`).selectAll("rect").call(drag);

    d3.select(`#${this.idTag}`).select("#control").on("contextmenu", function (evt) {
      console.log(evt, evt.shiftKey)
      evt.preventDefault();
      if (evt.shiftKey) {
        self.hasControl = false;
        self.onDragEnd();
      }
    })

    d3.select(`#${this.idTag}`).select("#mainLine").on("dblclick", function (evt) {
      let pt = d3.pointer(evt, d3.select(this).node());
      self.controlX = pt[0];
      self.controlY = pt[1];
      self.hasControl = true;
      self.onDragEnd();
    }).on("mouseover", function (evt) {
      if (!self.hasControl) {
        d3.select(this).style("cursor", "col-resize");
      }
    }).on("mouseout", function (evt) {
      d3.select(this).style("cursor", "default");
    });


    d3.select(`#${this.idTag}`).selectAll("#source,#target,#control")
      .on("mouseover", function (evt) {
        d3.select(this).style("cursor", (this.id == "control" && evt.shiftKey) ? "nesw-resize" : "move");
      })
      .on("mouseout", function (evt) {
        d3.select(this).style("cursor", "default");
      })
      .on("dblclick", function (evt) {
        if (evt.shiftKey) {
          self.hasControl = false;
          self.onDragEnd();
        } else if (self.nextAngle == 45) {
          self.hasControl = true;
        } else if (self.nextAngle == 90) {
          self.hasControl = true;
          self.controlX = self.sourceX;
          self.controlY = self.targetY;
          self.nextAngle = 90;
        }

        self.onDragEnd();
      });
  }

  private getAngle(x1: number, y1: number, x2: number, y2: number) {
    let x = x1 - x2,
      y = y1 - y2;
    if (!x && !y) {
      return 0;
    }
    return 360 - (180 + Math.atan2(-y, -x) * 180 / Math.PI + 360) % 360;
  }

  private onDragEnd() {
    const domainTargetX = Math.round(this.xScale.invert(this.targetX) * 10) / 10;
    const domainTargetY = Math.round(this.yScale.invert(this.targetY) * 10) / 10;
    let points = `${domainTargetX},${domainTargetY}`;

    if (this.sourceX != this.targetX || this.sourceY != this.targetY) {
      const domainSourceX = Math.round(this.xScale.invert(this.sourceX) * 10) / 10;
      const domainSourceY = Math.round(this.yScale.invert(this.sourceY) * 10) / 10;
      points += `;${domainSourceX},${domainSourceY}`;
    }

    if (this.hasControl) {
      const domainControlX = Math.round(this.xScale.invert(this.controlX) * 10) / 10;
      const domainControlY = Math.round(this.yScale.invert(this.controlY) * 10) / 10;
      points += `;${domainControlX},${domainControlY}`;
    }
    this.$emit("dragged", this.index, points);
  }

  private init(points: [number,number][]) {
    this.targetX = 300;
    this.targetY = 100;
    if (points.length > 0 && points[0][0]) {
      this.targetX = this.xScale(points[0][0]);
    }
    if (points.length > 0 && points[0][1]) {
      this.targetY = this.yScale(points[0][1]);
    }
    this.sourceX = this.targetX;
    this.sourceY = this.targetY;
    if (points.length > 1 && points[1][0]) {
      this.sourceX = this.xScale(points[1][0]);
    }
    if (points.length > 1 && points[1][1]) {
      this.sourceY = this.yScale(points[1][1]);
    }

    this.hasControl = points.length > 2;

    if (this.hasControl && points[2][0]) {
      this.controlX = this.xScale(points[2][0]);
    }

    if (this.hasControl && points[2][1]) {
      this.controlY = this.yScale(points[2][1]);
    }
  }

  private get startX(): number {
    return this.hasControl ? this.controlX : this.sourceX;
  }

  private get startY(): number {
    return this.hasControl ? this.controlY : this.sourceY;
  }
}
