













































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {TaxonomyTrait} from "@/service";
import {DefaultTaxonomyTrait} from "@/types/portraits";

@Component
export default class TraitCard extends Vue {


  @Prop({default: 0})
  readonly index!: number;

  @Prop({default: DefaultTaxonomyTrait})
  readonly trait!: TaxonomyTrait;

  @Prop({default: -1})
  readonly editingIndex!: number;

  @Prop({default: -1})
  readonly creatingIndex!: number;

  @Prop({default: "green"})
  readonly color!: string;

  private content = this.trait.chinese;

  private get outerIcon(): string {
    return this.inEditing ? "" : (this.trait.marker == 0 ? 'fal fa-plus' : "fal fa-trash-alt")
  }

  private get currentColor(): string {
    return this.inEditing ? "blue" : this.color;
  }

  private get firstPresence(): boolean {
    return this.trait.marker == 0;
  }

  private get inEditing(): boolean {
    return this.editingIndex == this.index;
  }

  private get inCreating(): boolean {
    return this.creatingIndex == this.index;
  }

  private editSelf() {
    if (!this.firstPresence) {
      this.$emit("on-edit");
    }
  }

  private onOuterAction() {
    if (this.firstPresence) {
      this.$emit("on-insert", this.trait)
    } else {
      this.$emit("on-delete", this.trait)
    }
  }

  private onSave() {
    const trait = Object.assign({}, this.trait, {
      chinese: this.content
    })

    if (this.inCreating) {
      this.$emit("do-create", trait);
    } else if (this.inEditing) {
      this.$emit("do-update", trait);
    }
  }

  private onCancel() {
    if (this.inCreating) {
      this.$emit("on-cancel-create", this.trait);
    } else {
      this.$emit("on-cancel-update", this.trait);
    }
  }

}
