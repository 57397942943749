import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class DialogMixin extends Vue {

  get show() {
    return this.value;
  }

  set show(val: boolean) {
    this.$emit('input', val);
  }

  @Prop()
  protected value = false;

}
